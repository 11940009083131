/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
var arrayCentrosCompleto=new Array();
var _validado = false;
(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Events = {
    // All pages
    'common': {
      init: function() {
        anchoVentana=$(window).width();
        altoVentana=$(window).height();
        new WOW().init();
        initParallax()
       $('#mobile-menu').click(function(){
            $('#nav-mobile').hide();
            $('#share-mobile').hide();
            if($('.header-container').hasClass('abierto')){
                $('.header-container').removeClass('abierto');
                $('.header-container').removeClass('abierto-menu');
                $('.header-container').removeClass('abierto-share');
                $('#nav-mobile').hide();
                $('#share-mobile').hide();
            }
            else{
                $('.header-container').addClass('abierto');
                $('.header-container').addClass('abierto-menu');
                $('#nav-mobile').show();
            }
        });
        
        $('#mobile-share').click(function(){
            $('#nav-mobile').hide();
            $('#share-mobile').hide();
            if($('.header-container').hasClass('abierto')){
                $('.header-container').removeClass('abierto');
                $('.header-container').removeClass('abierto-menu');
                $('.header-container').removeClass('abierto-share');
                $('#nav-mobile').hide();
                $('#share-mobile').hide();
            }
            else{
                $('.header-container').addClass('abierto');
                $('.header-container').addClass('abierto-share');
                $('#share-mobile').show();
            }
        });
        
        //submenu productos
        $("#productos")
        .mouseover(function() {
            $('.submenu-productos').addClass('visible');
        }) 
        .mouseout(function() {
            $('.submenu-productos').removeClass('visible');
        });
        
        $(".submenu-productos")
        .mouseover(function() {
            $('.submenu-productos').addClass('visible');
        })
        .mouseout(function() {
            $('.submenu-productos').removeClass('visible');
        });
        
        //submenu newsletter
        $("#newsletter")
        .mouseover(function() {
            $('.submenu-newsletter').addClass('visible');
        })
        .mouseout(function() {
            $('.submenu-newsletter').removeClass('visible');
        });
        
        $(".submenu-newsletter")
        .mouseover(function() {
            $('.submenu-newsletter').addClass('visible');
        })
        .mouseout(function() {
            $('.submenu-newsletter').removeClass('visible');
        });
               
        $("#enviar_newsletter").click(function() {
            if (!$('#acepta_newsletter').is(':checked')) {
                $('#result_newsletter').html('Debes aceptar nuestras bases legales.');
            }
            else if($('#email_newsletter').val() != ''){
                if(validateEmail($('#email_newsletter').val())){
                    
                    $.post("http://www.smint.es/smint/inscribir_newsletter", { 
                        email        :   $('#email_newsletter').val()
                    }, function(data) {

                        if(data == 'ok'){
                            $('#result_newsletter').html('!Te has inscrito a nuestra newsletter con éxito!');
                            $('.enviar_newsletter').hide();
                        }
                        else if(data == 'repetido'){
                            $('#result_newsletter').html('Ya estabas inscrito en nuestra newsletter.');
                            $('.enviar_newsletter').hide();
                        }
                        else{
                            $('#result_newsletter').html('Se ha producido un error. Inténtalo más tarde.');
                        }
                    });
                }
                else{
                    $('#result_newsletter').html('Esto no parece un mail válido.');
                }
            }
        });
        $('#myModal').on('hidden.bs.modal', function() {
              $("#myModal .modal-body p").html('');
          })


      },
      finalize: function() {
      }
    },




    'index': {
      init: function() {


        var mySwiper;
        mySwiper = new Swiper('.swiper-container-galeria', {
            slidesPerView: 4,
            nextButton: '.swiper-button-next',
            prevButton: '.swiper-button-prev',
            breakpoints: {
                1700: {
                    slidesPerView: 3,
                    spaceBetween: 0
                },
                1299: {
                    slidesPerView: 3,
                    spaceBetween: 0
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 0
                },
                640: {
                    slidesPerView: 1,
                    spaceBetween: 0
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                }
            }
        });

      },
      finalize: function() {
      }
    },


    'conversor': {
      init: function() {

        $(".js-validarEmailRRSS").click(function(event) {
            
            $("#emailRRSSRegistro").removeClass('error');

            if ( validateEmail($("#emailRRSSRegistro").val()) ){

                $("#emailRegistro").val($("#emailRRSSRegistro").val());

                irPaso2();


            }else{

                $("#emailRRSSRegistro").addClass('error');

            }


        });


        $(".js-validarRegistroLocal").click(function(event) {

            error=false;

            $("#nombreLocal").removeClass('error');
            if ($("#nombreLocal").val()==""){
                error=true;
                $("#nombreLocal").addClass('error');
            }

            $("#emailLocal").removeClass('error');
            if (!validateEmail($("#emailLocal").val())){
                error=true;
                $("#emailLocal").addClass('error');
            }      

            if (error==false){

                $("#emailRegistro").val($("#emailLocal").val());
                $("#nombreRegistro").val($("#nombreLocal").val());
                $("#tipoRRSSRegistro").val("local");

                irPaso2();

            }      


        });


        $(".js-validarFinal").click(function(event) {
           
            var file="";
            var fileInput = document.getElementById('userPhoto');

            if (typeof fileInput.files[0] !== 'undefined') {
              file = fileInput.files[0];
            }



            if (file!="" &&  $("#nombreRegistro").val()!="" && $("#emailRegistro").val()!="" &&  $("#checkBases").is(":checked") &&  $("#checkPolitica").is(":checked") ){


                var formData = new FormData();       

                formData.append('nombreRegistro', $("#nombreRegistro").val());
                formData.append('emailRegistro', $("#emailRegistro").val());
                formData.append('tipoRRSSRegistro', $("#tipoRRSSRegistro").val());
                formData.append('idRRSSRegistro', $("#idRRSSRegistro").val());
                formData.append('dataRRSSRegistro', $("#dataRRSSRegistro").val());
                formData.append('archivo', file);

                 $(".contenedor-sube-tu-beso .cargadorSubirFoto").show();

                $.ajax({
                    url: 'ajax/ajax-guardarRegistro.php',
                    data: formData,
                    cache: false,
                    contentType: false,
                    processData: false,
                    type: 'POST',
                    dataType: "json",
                    success: function(data){

                        if (data.resultado=="OK"){

                            $(".contenedor-sube-tu-beso .desactivador").hide();
                            $(".contenedor-sube-tu-beso .cargadorSubirFoto").hide();

                            //inicializo todo.
                            $("#emailLocal").val("");
                            $("#nombreLocal").val("");
                            $("#tipoRRSSRegistro").val("");

                            $("#emailRegistro").val("");
                            $("#nombreRegistro").val("");
                            $("#tipoRRSSRegistro").val("");
                            $("#idRRSSRegistro").val("");
                            $("#dataRRSSRegistro").val("");
                            $("#emailRRSSRegistro").val("");
                            $("#emailRRSSRegistro").val("");

                            $(".modLogin").show();
                            $(".modConfirmaremail").hide();

                            $(".contenedorImagen").css("background-image",'url("dist/images/conversor_foto_beso_ref.jpg")');

                            $(".contenedor-sumate").css("opacity","1");
                            $(".contenedor-sumate .desactivador").hide();   

                            $("#checkPolitica").removeAttr('checked');
                            $("#checkBases").removeAttr('checked');   

                            $("#userPhoto").html($("#control").html());                      

                            alerta ("TU BESO REAL ESTÁ SIENDO VALIDADO.","CONSÚLTALO EN LA <a href='galeria.php'>GALERÍA</a>");

                        }else{

                          alerta ("ERROR","Ha ocurrido un error, inténtalo de nuevo más tarde.");

                        }

                        $(".contenedor-sube-tu-beso .cargadorSubirFoto").hide();



                    }



                });


            }else{

                alerta ("ERROR","Comprueba que has rellenado todos los pasos anteriores y has aceptado la política de privacidad y bases legales para continuar.");

            }



        });

      },
      finalize: function() {
      }
    }, 



    'galeria': {
      init: function() {


        irPaginaGaleria(0);


        $(".js-filtro").click(function(event) {
          
          if (!$(this).hasClass('activo')){
            $(".js-filtro").removeClass("activo");
            $(this).addClass('activo');
            irPaginaGaleria(0);

          }

        });

        var timeoutBusqueda="";

        $(".filtro-usuario").on('change keydown paste input', function(){
          
          clearTimeout(timeoutBusqueda);

          timeoutBusqueda=setTimeout(function(){

            irPaginaGaleria(0);

          }, 200);

          

        });


         


      },
      finalize: function() {
      }
    },       


  };

  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Events;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.



function initParallax(){
  $(".js-parallax").each(function(index){
    if(anchoVentana>768){
     $(this).attr("Yini",$(this).offset().top);
    }
  });
  $(".js-parallax-bg").each(function(index){
    if(anchoVentana>768){
      var scrollTop = $(window).scrollTop();
      var vel =$(this).data("parallax-bg-velocidad");
      var extraY=$(this).data("parallax-bg-extray");
      var Yini=Math.round($(this).attr("Yini"));
      var posY=parseInt((altoVentana-scrollTop)*vel);
      debuggear("Y >> "+posY);
      $(this).css("background-position","center "+(posY+extraY)+"px");
    }
  });
  $(window).scroll(function(){
    if(anchoVentana>768){
      drawParallax();
    }
  })
}
function drawParallax(){
  $(".js-parallax").each(function(index){
    if(anchoVentana>768){
      var scrollTop = $(window).scrollTop();
      var vel =$(this).data("parallax-velocidad");
      var extraY=$(this).data("parallax-extray");
      var Yini=Math.round($(this).attr("Yini"));
      var posY=parseInt((Yini-scrollTop)*vel);
      // debuggear("Y >> "+scrollTop+" :: "+posY);
      $(this).css("margin-top",(posY+extraY)+"px");
    }
  });
  $(".js-parallax-bg").each(function(index){
    if(anchoVentana>768){
      var scrollTop = $(window).scrollTop();
      var vel =$(this).data("parallax-bg-velocidad");
      var extraY=$(this).data("parallax-bg-extray");
      var Yini=Math.round($(this).attr("Yini"));
      var posY=parseInt((altoVentana-scrollTop)*vel);
      debuggear("Y >> "+posY);
      $(this).css("background-position","center "+(posY+extraY)+"px");
    }
  });
}


/**
  * Hace scroll animado a un target.
  *
  * @obj objeto al que hace scroll
  * @offset distancia extra a su posición actual
  */
function scrollToDom(_obj,_offset){
    var seccion = $(_obj);
    var scrollDistance = Math.abs(Math.round(seccion.offset().top) - Math.round($('html, body').scrollTop()));
    var velocityFactor = 1;
    var velocity =  velocityFactor*Math.round((1 / 900) * scrollDistance + 5/9);
    var posY=seccion.offset().top-_offset;
    var tiempoAnimacion=scrollDistance/velocity;
    $('html, body').stop().animate({scrollTop:posY}, tiempoAnimacion*2, "swing", function() { 
    });
}




function debuggear(_var){
    console.log(_var);
}

function alerta(_tit,_txt){

    $("#myModal .modal-body p").html(_tit+"<br/><small>"+_txt+"</small");
    $("#myModal").modal("show");

}


function popup(obj){
    w = window.open(obj.href, obj.title, "width=600,height=500");
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

function facebookLogin(urlRedirect){

    FB.login(function(response) {
       if (response.authResponse) {
         FB.api('/me', function(_data) {

            //guardo los datos
            $("#nombreRegistro").val(_data.name);
            $("#idRRSSRegistro").val(_data.id);
            $("#dataRRSSRegistro").val(encodeURIComponent(_data));
            $("#tipoRRSSRegistro").val("facebook");

            jQuery(".modLogin").hide();
            jQuery(".modConfirmaremail").show();

         });
       } else {
         
       }
    }, {scope: 'email'}
    );

}


function responseTwitter(_data){

    //guardo los datos
    $("#nombreRegistro").val(_data.name);
    $("#idRRSSRegistro").val(_data.id);
    $("#dataRRSSRegistro").val(encodeURIComponent(_data));
    $("#tipoRRSSRegistro").val("twitter");        


    jQuery(".modLogin").hide();
    jQuery(".modConfirmaremail").show();


}


function responseInstagram(_data){

    debuggear(_data);

    //guardo los datos
    $("#nombreRegistro").val(_data.user.username);
    $("#idRRSSRegistro").val(_data.user.id);
    $("#dataRRSSRegistro").val(encodeURIComponent(_data));
    $("#tipoRRSSRegistro").val("instagram");         

    jQuery(".modLogin").hide();
    jQuery(".modConfirmaremail").show();        

}


function irPaso2(){

    $(".contenedor-sumate").css("opacity","0.5");
    $(".contenedor-sumate .desactivador").show();

    $("html, body").animate({ scrollTop: $('.contenedor-sube-tu-beso').offset().top - 50 }, 1000);

}


function changePhoto($target){

    debuggear("cambio imagen");

    datosfoto="";

    var file = $target.files[0];

    if (file) {
        if (/^image\//i.test(file.type)) {

            readFile(file);

        } else {

            if (/^video\//i.test(file.type)) {

                $(".contenedorImagen").css("background-image","url('dist/images/subidoVideo.gif')");

            }else{

                alerta ("ERROR","El archivo que has elegido no tiene un formato válido, por favor, selecciona otro.");

            }

        }
    }       

}


function readFile(file) {

    var reader = new FileReader();

    reader.onloadend = function () {
        processFile(reader.result, file.type);
    }

    reader.onerror = function () {

        alerta ("ERROR","Ha ocurrido un error leyendo el archivo, por favor, selecciona otro");

    }

    reader.readAsDataURL(file);

}



function processFile(dataURL, fileType) {

  var maxWidth = 480;
  var maxHeight = 480;

  var image = new Image();
  image.src = dataURL;

  image.onload = function () {
    var width = image.width;
    var height = image.height;
    var shouldResize = (width > maxWidth) || (height > maxHeight);

    var orientation = 0;
    EXIF.getData(image, function () {
        orientation = this.exifdata.Orientation;
    });

    var newWidth;
    var newHeight;

    if (width > height) {
      newHeight = height * (maxWidth / width);
      newWidth = maxWidth;
    } else {
      newWidth = width * (maxHeight / height);
      newHeight = maxHeight;
    }

    var canvas = document.createElement('canvas');

    canvas.width = newWidth;
    canvas.height = newHeight;

    var ctx = canvas.getContext('2d');

    switch(orientation){
      case 2:
          // horizontal flip
          ctx.translate(canvas.width, 0);
          ctx.scale(-1, 1);
          break;
      case 3:
          // 180° rotate left
          ctx.translate(canvas.width, canvas.height);
          ctx.rotate(Math.PI);
          break;
      case 4:
          // vertical flip
          ctx.translate(0, canvas.height);
          ctx.scale(1, -1);
          break;
      case 5:
          // vertical flip + 90 rotate right
          ctx.rotate(0.5 * Math.PI);
          ctx.scale(1, -1);
          break;
      case 6:
          // 90° rotate right
          ctx.rotate(0.5 * Math.PI);
          ctx.translate(0, -canvas.height);
          break;
      case 7:
          // horizontal flip + 90 rotate right
          ctx.rotate(0.5 * Math.PI);
          ctx.translate(canvas.width, -canvas.height);
          ctx.scale(-1, 1);
          break;
      case 8:
          // 90° rotate left
          ctx.rotate(-0.5 * Math.PI);
          ctx.translate(-canvas.width, 0);
          break;
    } 

    ctx.drawImage(this, 0, 0, newWidth, newHeight);

    if (dataURL = canvas.toDataURL(fileType)){

        //sendFile(dataURL);
        debuggear("pongo la foto");
        //$("#imgPreviewBeso").attr("src", dataURL);

        setTimeout(function(){

            $(".contenedorImagen").css("background-image",'url(' + dataURL + ')');

        },1000)

    }
    
    
  };

  image.onerror = function () {
    alerta ("ERROR","Ha ocurrido un error al procesar tu archivo, por favor, selecciona otro.");
  };
}



function irPaginaGaleria(_pag){

    var busqueda=$(".filtro-usuario").val();

    var filtro="";
    $(".js-filtro").each(function(index, el) {
      if ($(this).hasClass('activo')){

        filtro=$(this).attr("data-filtro");

      }
    });

    paginaHome = _pag;


    $.ajax({
        url: 'ajax/ajax-galeria.php',
        data: {pagina: _pag, filtro: filtro, busqueda: busqueda},
        type: 'POST',
        success: function(data){

          $("#galeriaContainer").html(data);

          pintarPaginacion();


        }

    });


}


var paginaHome;
var paginasTotal=0;

function pintarPaginacion(){
  paginasTotal = $('#totalPaginas').val();

  if (paginasTotal>1){
    $(".contenedor-pagination").load('paginacion.php?total='+paginasTotal+"&activa="+paginaHome); 
  }else{
    $(".contenedor-pagination").html(""); 
  }

   
}

function siguientePagina(){
  if(paginaHome<paginasTotal-1){
    irPaginaGaleria(paginaHome+1);
  }
}
function anteriorPagina(){
  if(paginaHome>0){
    irPaginaGaleria(paginaHome-1);
  }
}

